import classNames from "classnames";
import RandomNumber from "components/RandomNumber/RandomNumber";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatDateBasic } from "utils/formateDate";
import {
  convertNameLotteryToParam,
  formatGetNameLottery,
  handleCheckDauDuoiHighlight,
  numberOfPrizes,
} from "utils/lotteryFormat";
import FadeLoaderComponent from "./FadeLoaderComponent";
import "./Table.scss";
interface PropsI {
  data: any;
  listName: any;
  region: string;
  typeSlice: string;
  isToday: boolean;
  changedPrizesInfo: any;
  dataHoverDauDuoi: any;
  dataSelectDauDuoi: any;
  listDataHoverDauDuoi: any;
  listDataSelectDauDuoi: any;
}
function TableLotteryMBComponent({
  data,
  listName,
  region,
  typeSlice,
  isToday,
  changedPrizesInfo,
  dataHoverDauDuoi,
  dataSelectDauDuoi,
  listDataHoverDauDuoi,
  listDataSelectDauDuoi,
}: PropsI) {
  const { listGames } = useSelector((state: any) => state.lottery);
  const { isMobile } = useSelector((state: any) => state.layout);
  const handleConvertResultPrize = (arrPrize: any, namePrize: string) => {
    const defaultLength = (() => {
      if (region === "mb") {
        if (["giaibay", "giaitu"].includes(namePrize)) return 4;
        if (namePrize === "giaisau") return 3;
        if (["giaiba", "giainam"].includes(namePrize)) return 6;
        if (namePrize === "giainhi") return 2;
        return 1;
      } else {
        if (namePrize === "giaisau") return 3;
        if (namePrize === "giaitu") return 7;
        if (namePrize === "giaiba") return 2;
        return 1;
      }
    })();

    const resultArray: any[] = [];

    arrPrize?.forEach((prize: string) => {
      resultArray.push(prize?.length > 0 ? prize : "-");
    });

    while (resultArray?.length < defaultLength) {
      resultArray?.push("-");
    }

    return resultArray;
  };

  const handleSliceNumber = (value: string) => {
    return typeSlice === "3so"
      ? value.slice(-3)
      : typeSlice === "2so"
      ? value.slice(-2)
      : value;
  };

  const handleRenderPrize = (
    prizeValue: any,
    indexParent: number,
    indexChild: number
  ) => {
    if (prizeValue) {
      return handleSliceNumber(prizeValue);
    }
  };

  const isChangedPrize = (gameid: number, prizeName: string, index: number) => {
    return changedPrizesInfo?.some((prize: any) => {
      return prize.gameid === gameid && prize[prizeName]?.includes(index);
    });
  };
  const renderFilted = (value: any, index: any, prizeIndex: any) => {
    const listDataSelectDauDuoiByIndex = listDataSelectDauDuoi?.find(
      (itemSelect: any) => itemSelect.index === index
    );
    const listDataHoverDauDuoiByIndex = listDataHoverDauDuoi?.find(
      (itemHover: any) => itemHover.index === index
    );
    const dataHoverByIndex =
      dataHoverDauDuoi?.indexParent === index ? dataHoverDauDuoi : null;

    const dataSelectByIndex = dataSelectDauDuoi?.find(
      (itemSelect: any) => itemSelect.index === index
    );
    return (
      <Fragment>
        {value && String(value) !== "-" ? (
          <span
            className="flex items-center"
            dangerouslySetInnerHTML={{
              __html: handleCheckDauDuoiHighlight(
                handleRenderPrize(value, index, prizeIndex),
                dataHoverByIndex,
                dataSelectByIndex,
                listDataHoverDauDuoiByIndex,
                listDataSelectDauDuoiByIndex
              ),
            }}
          ></span>
        ) : (
          // ) : (
          //   <span
          //     className="flex items-center"
          //     dangerouslySetInnerHTML={{
          //       __html: handleCheckDauDuoiHightLightByNumber(
          //         handleRenderPrize(value, index, prizeIndex),
          //         numberSelected !== undefined && numberSelected !== null
          //           ? numberSelected?.toString()
          //           : null,
          //         numberHovered?.toString()
          //       ),
          //     }}
          //   ></span>
          // )
          <FadeLoaderComponent />
        )}
      </Fragment>
    );
  };

  return (
    <table width="100%" className="text-black dark:text-white bkqmiennam">
      <tbody>
        <tr>
          <td width={isMobile ? "70" : "100"}>
            <table className="leftcl" width="100%">
              <tbody>
                <tr className="bg-surface-tertiary dark:bg-bgPrimary">
                  <td
                    className={classNames(
                      "thu h-[49px] text-sm md:text-[11px] border-b border-r border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center",
                      { "!h-8": isMobile }
                    )}
                  >
                    {formatDateBasic(data && data[0]?.timeDay)}
                  </td>
                </tr>
                <tr>
                  <td
                    className={classNames(
                      "h-14 text-sm md:text-[12px] font-semibold border-r border-b border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center giaidb",
                      { "!h-9": isMobile }
                    )}
                  >
                    Đặc Biệt
                  </td>
                </tr>
                <tr>
                  <td
                    className={classNames(
                      "h-12 text-sm md:text-[12px] font-semibold border-r border-b border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center giai1",
                      { "!h-8": isMobile }
                    )}
                  >
                    Nhất
                  </td>
                </tr>
                <tr className="">
                  <td
                    className={classNames(
                      "h-12 text-sm md:text-[12px] font-semibold border-r border-b border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center giai2",
                      { "!h-8": isMobile }
                    )}
                  >
                    Nhì
                  </td>
                </tr>
                <tr className="">
                  <td
                    className={classNames(
                      "h-16 text-sm md:text-[12px] font-semibold border-r border-b border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center giai3",
                      { "!h-14": isMobile }
                    )}
                  >
                    Ba
                  </td>
                </tr>
                <tr className="">
                  <td
                    className={classNames(
                      "h-12 text-sm md:text-[12px] font-semibold border-r border-b border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center giai4",
                      { "!h-8": isMobile }
                    )}
                  >
                    Tư
                  </td>
                </tr>
                <tr>
                  <td
                    className={classNames(
                      "h-16 text-sm md:text-[12px] font-semibold border-r border-b border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center giai5",
                      { "!h-14": isMobile }
                    )}
                  >
                    Năm
                  </td>
                </tr>
                <tr>
                  <td
                    className={classNames(
                      "h-12 text-sm md:text-[12px] font-semibold border-r border-b border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center giai6",
                      { "!h-8": isMobile }
                    )}
                  >
                    Sáu
                  </td>
                </tr>
                <tr>
                  <td
                    className={classNames(
                      "h-14 text-sm md:text-[12px] font-semibold border-r border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center giai7",
                      { "!h-9": isMobile }
                    )}
                  >
                    Bảy
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td valign="top">
            <table width="100%">
              <tbody>
                <tr>
                  {data?.length > 0 &&
                    data?.map((item: any, index: any) => (
                      <td valign="top" width="50%" key={index}>
                        <table
                          className="rightcl bangketquaSo bangketquaSo_0"
                          width="100%"
                        >
                          <tbody>
                            <tr>
                              <td
                                className={classNames(
                                  "thu h-12 text-base bg-surface-tertiary dark:bg-bgPrimary font-bold flex items-center justify-center text-center text-primary",
                                  { "!text-sm !h-8": isMobile }
                                )}
                              >
                                <Link
                                  to={`./${convertNameLotteryToParam(
                                    formatGetNameLottery(
                                      item?.gameid,
                                      listGames
                                    )
                                  )}`}
                                  className="hover:underline"
                                >
                                  {formatGetNameLottery(
                                    item?.gameid,
                                    listGames
                                  )}
                                </Link>
                              </td>
                            </tr>

                            <tr>
                              <td
                                className={classNames(
                                  "h-14 border-b border-t border-borderLightPrimary dark:border-borderPrimary giaidb ",
                                  { "!h-9": isMobile }
                                )}
                              >
                                {handleConvertResultPrize(
                                  item.giaidb?.toString()?.split(","),
                                  "giaidb"
                                ).map((giaidb: any, prizeIndex: number) => (
                                  <div
                                    key={prizeIndex}
                                    className={classNames(
                                      "giaiSo w-full rounded-md flex items-center justify-center text-warning-500 text-2xl",
                                      { "text-base": isMobile }
                                    )}
                                  >
                                    <span className=" w-full rounded-md flex  items-center justify-center font-semibold">
                                      {isChangedPrize(
                                        item?.gameid,
                                        "giaidb",
                                        prizeIndex
                                      ) ? (
                                        <RandomNumber
                                          length={numberOfPrizes(
                                            region,
                                            "giaidb",
                                            typeSlice
                                          )}
                                        />
                                      ) : (
                                        renderFilted(giaidb, index, prizeIndex)
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td
                                className={classNames(
                                  "h-12 border-b border-borderLightPrimary dark:border-borderPrimary giainhat",
                                  { "!h-8": isMobile }
                                )}
                              >
                                {handleConvertResultPrize(
                                  item.giainhat?.toString()?.split(","),
                                  "giainhat"
                                ).map((giainhat: any, prizeIndex: number) => (
                                  <div
                                    key={prizeIndex}
                                    className="giaiSo w-full rounded-md flex items-center justify-center text-black dark:text-white"
                                  >
                                    <span
                                      className={classNames(
                                        " w-full rounded-md flex  items-center justify-center font-semibold text-lg",
                                        { "!text-sm": isMobile }
                                      )}
                                    >
                                      {isChangedPrize(
                                        item?.gameid,
                                        "giainhat",
                                        prizeIndex
                                      ) ? (
                                        <RandomNumber
                                          length={numberOfPrizes(
                                            region,
                                            "giainhat",
                                            typeSlice
                                          )}
                                        />
                                      ) : (
                                        renderFilted(
                                          giainhat,
                                          index,
                                          prizeIndex
                                        )
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td
                                className={classNames(
                                  "h-12 border-b border-borderLightPrimary dark:border-borderPrimary giainhi grid gap-0 grid-cols-2 gap-x-2",
                                  { "!h-8": isMobile }
                                )}
                              >
                                {handleConvertResultPrize(
                                  item.giainhi?.toString()?.split(","),
                                  "giainhi"
                                ).map((giainhi: any, prizeIndex: number) => (
                                  <div
                                    key={prizeIndex}
                                    className="giaiSo text-sm w-full rounded-md flex items-center justify-center text-black dark:text-white "
                                  >
                                    <span className="w-full rounded-md flex  items-center justify-center font-semibold">
                                      {isChangedPrize(
                                        item?.gameid,
                                        "giainhi",
                                        prizeIndex
                                      ) ? (
                                        <RandomNumber
                                          length={numberOfPrizes(
                                            region,
                                            "giainhi",
                                            typeSlice
                                          )}
                                        />
                                      ) : (
                                        renderFilted(giainhi, index, prizeIndex)
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td
                                className={classNames(
                                  "h-16 border-b border-borderLightPrimary dark:border-borderPrimary giaiba grid grid-cols-3 gap-0  py-1",
                                  { "!h-14": isMobile }
                                )}
                              >
                                {handleConvertResultPrize(
                                  item.giaiba?.toString()?.split(","),
                                  "giaiba"
                                ).map((giaiba: any, prizeIndex: number) => (
                                  <div
                                    key={prizeIndex}
                                    className="giaiSo text-sm w-full rounded-md flex items-center justify-center text-black dark:text-white"
                                  >
                                    <span className=" w-full rounded-md flex items-center justify-center font-semibold">
                                      {isChangedPrize(
                                        item?.gameid,
                                        "giaiba",
                                        prizeIndex
                                      ) ? (
                                        <RandomNumber
                                          length={numberOfPrizes(
                                            region,
                                            "giaiba",
                                            typeSlice
                                          )}
                                        />
                                      ) : (
                                        renderFilted(giaiba, index, prizeIndex)
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td
                                className={classNames(
                                  "h-12 border-b border-borderLightPrimary dark:border-borderPrimary giaitu grid gap-0 grid-cols-4",
                                  { "!h-8": isMobile }
                                )}
                              >
                                {handleConvertResultPrize(
                                  item.giaitu?.toString()?.split(","),
                                  "giaitu"
                                ).map((giaitu: any, prizeIndex: number) => (
                                  <div
                                    key={prizeIndex}
                                    className="giaiSo text-sm w-full rounded-md flex items-center justify-center text-black dark:text-white "
                                  >
                                    <span className=" w-full rounded-md flex  items-center justify-center font-semibold">
                                      {isChangedPrize(
                                        item?.gameid,
                                        "giaitu",
                                        prizeIndex
                                      ) ? (
                                        <RandomNumber
                                          length={numberOfPrizes(
                                            region,
                                            "giaitu",
                                            typeSlice
                                          )}
                                        />
                                      ) : (
                                        renderFilted(giaitu, index, prizeIndex)
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td
                                className={classNames(
                                  "h-16 border-b border-borderLightPrimary dark:border-borderPrimary giainam grid gap-0 grid-cols-3  py-1",
                                  { "!h-14": isMobile }
                                )}
                              >
                                {handleConvertResultPrize(
                                  item.giainam?.toString()?.split(","),
                                  "giainam"
                                ).map((giainam: any, prizeIndex: number) => (
                                  <div
                                    key={prizeIndex}
                                    className="giaiSo text-sm w-full rounded-md flex items-center justify-center text-black dark:text-white "
                                  >
                                    <span className=" w-full rounded-md flex  items-center justify-center font-semibold">
                                      {isChangedPrize(
                                        item?.gameid,
                                        "giainam",
                                        prizeIndex
                                      ) ? (
                                        <RandomNumber
                                          length={numberOfPrizes(
                                            region,
                                            "giainam",
                                            typeSlice
                                          )}
                                        />
                                      ) : (
                                        renderFilted(giainam, index, prizeIndex)
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td
                                className={classNames(
                                  "h-12 border-b border-borderLightPrimary dark:border-borderPrimary giaisau grid gap-1 grid-cols-3 gap-x-2",
                                  { "!h-8": isMobile }
                                )}
                              >
                                {handleConvertResultPrize(
                                  item.giaisau?.toString()?.split(","),
                                  "giaisau"
                                ).map((giaisau: any, prizeIndex: number) => (
                                  <div
                                    key={prizeIndex}
                                    className="giaiSo text-sm w-full rounded-md flex items-center justify-center text-black dark:text-white "
                                  >
                                    <span className=" w-full rounded-md flex  items-center justify-center font-semibold">
                                      {isChangedPrize(
                                        item?.gameid,
                                        "giaisau",
                                        prizeIndex
                                      ) ? (
                                        <RandomNumber
                                          length={numberOfPrizes(
                                            region,
                                            "giaisau",
                                            typeSlice
                                          )}
                                        />
                                      ) : (
                                        renderFilted(giaisau, index, prizeIndex)
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td
                                className={classNames(
                                  "h-14 border-borderLightPrimary dark:border-borderPrimary giaibay grid gap-0 grid-cols-4 gap-x-2",
                                  { "!h-9": isMobile }
                                )}
                              >
                                {handleConvertResultPrize(
                                  item.giaibay?.toString()?.split(","),
                                  "giaibay"
                                ).map((giaibay: any, prizeIndex: number) => (
                                  <div
                                    key={prizeIndex}
                                    className="giaiSo  w-full rounded-md flex items-center justify-center text-warning-500 font-semibold "
                                  >
                                    <span
                                      className={classNames(
                                        " w-full rounded-md flex  items-center justify-center font-semibold text-xl",
                                        { "!text-base": isMobile }
                                      )}
                                    >
                                      {isChangedPrize(
                                        item?.gameid,
                                        "giaibay",
                                        prizeIndex
                                      ) ? (
                                        <RandomNumber
                                          length={numberOfPrizes(
                                            region,
                                            "giaibay",
                                            typeSlice
                                          )}
                                        />
                                      ) : (
                                        renderFilted(giaibay, index, prizeIndex)
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    ))}
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default TableLotteryMBComponent;
