import classNames from "classnames";
import { listMenuPublic, listSocial } from "constant";
import { addThemeMode } from "features/layoutSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { handleRedirectLink } from "utils/commonFormat";
import { icons } from "utils/icons";

const MenuMobile = (props: any) => {
  const { onClose, isShow } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mode } = useSelector((state: any) => state.layout);

  const { configSeo } = useSelector((state: any) => state?.seo);

  const handleNavigate = (path: any, isPublic?: boolean) => {
    navigate(path);
    onClose();
  };

  const handleToggleMode = (e: any) => {
    if (mode === 0) {
      dispatch(addThemeMode(1));
      localStorage.setItem("darkMode", "true");
    } else {
      dispatch(addThemeMode(0));
      localStorage.setItem("darkMode", "false");
    }
  };

  return (
    <div
      className={classNames(
        "fixed top-0 left-0 w-full py-2 px-4 shadow-[0px_10px_20px_0px_#0000004D] bg-surface-tertiary dark:bg-bgPrimary flex flex-col -translate-y-[120%] transition-all duration-500",
        { "!translate-y-0": isShow }
      )}
    >
      <div className="flex items-center justify-between mb-3">
        <img
          src={require("assets/images/icon-logo.png")}
          className="h-10"
          alt={configSeo?.title}
        />
        <button
          type="button"
          className="bg-primary/10 hover:bg-primary/30 p-1 rounded-lg border-none cursor-pointer"
          onClick={onClose}
        >
          <img
            src={require("assets/icons/close.png")}
            className="w-5 h-5"
            alt="close"
          />
        </button>
      </div>

      <div className="flex flex-col">
        {listMenuPublic?.map((menu: any, index: number) => (
          <div
            className="flex items-center py-2 px-2.5 text-text-secondary dark:text-white/40 active:text-black dark:active:text-white"
            key={index}
            onClick={() => {
              if (menu.isNewTab) {
                handleRedirectLink(menu.path);
              } else {
                handleNavigate(menu.path, menu.isPublic);
              }
            }}
          >
            <div
              className="mr-2.5"
              dangerouslySetInnerHTML={{ __html: menu.icon }}
            />
            <span className="text-sm font-semibold">{menu.title}</span>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-between mt-2">
        <button
          className="btn-signup flex items-center justify-center w-9 h-9 bg-primary/20 outline-none rounded-[10px]"
          type="button"
          onClick={handleToggleMode}
        >
          <img
            src={mode === 1 ? icons.sun : icons.moon}
            className="icon-header text-primary w-5 h-5"
            alt={configSeo?.title}
          />
        </button>
        <div className="flex items-center">
          {listSocial?.map((social: any, index: number) => (
            <div className="w-6 h-6 group ml-4 cursor-pointer" key={index}>
              <img
                src={social.iconHover}
                className={classNames("w-full h-full  block", {})}
                alt={configSeo?.title}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MenuMobile;
