import classNames from "classnames";
import BounceLoadingComponent from "components/BounceLoadingComponent/BounceLoadingComponent";
import ListDaysPopover from "components/Common/ListDaysPopover";
import { listDays } from "constant";
import useToast from "hooks/useToast";
import { ltOpenDay } from "interfaces/list-lottery";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { compareVietnameseStrings } from "utils/commonFormat";
import {
  convertNameLotteryToParam,
  formatGetTitleStatus,
} from "utils/lotteryFormat";

const MenuResult = ({ className, onClose }: any) => {
  const navigate = useNavigate();
  const toastify = useToast();

  const popoverDayRef = useRef<any>();

  const { isAuth } = useSelector((state: any) => state.auth);
  const { listGames, listLotteryToday } = useSelector(
    (state: any) => state.lottery
  );
  const { isMobile, isTablet } = useSelector((state: any) => state.layout);

  const [listLotteryFilter, setListLotteryFilter] = useState<any>([]);
  const [showPopoverDays, setShowPopoverDays] = useState<any>({
    isShow: false,
    type: null,
  });
  const [curretDayMiddle, setCurrentDayMiddle] = useState<any>(
    new Date().getDay()
  );
  const [curretDaySouth, setCurrentDaySouth] = useState<any>(
    new Date().getDay()
  );

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        popoverDayRef.current &&
        !popoverDayRef.current.contains(event.target)
      ) {
        setShowPopoverDays({ isShow: false, type: null });
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const listGamesFilter = handleGroupMenuGames(
      listGames?.filter(
        (game: any) => [1, 2, 3].includes(game.type) && game.id !== 201
      )
    );
    const listIdGameMiddleByWeekday: any = ltOpenDay.find(
      (item: any) => ltOpenDay.indexOf(item) === curretDayMiddle
    );
    const listIdGameSouthByWeekday: any = ltOpenDay.find(
      (item: any) => ltOpenDay.indexOf(item) === curretDaySouth
    );
    const listLotteryConvert = listGamesFilter?.map((game: any) => {
      if (game.type === 1) {
        game.listMenuItem =
          curretDaySouth === 7
            ? game.listMenuItem
            : handleFilterArrWithArr(
                game.listMenuItem,
                listIdGameSouthByWeekday?.split(",")
              );
      }
      if (game.type === 2) {
        game.listMenuItem =
          curretDayMiddle === 7
            ? game.listMenuItem
            : handleFilterArrWithArr(
                game.listMenuItem,
                listIdGameMiddleByWeekday?.split(",")
              );
      }
      return game;
    });
    setListLotteryFilter(listLotteryConvert);
  }, [curretDayMiddle, curretDaySouth, listGames]);

  const handleFilterArrWithArr = (arr1: any, arr2: any) => {
    const numberArray = arr2?.map((item: any) => Number(item));
    const arrFilter = arr1?.filter((item: any) =>
      numberArray?.includes(item.id)
    );
    return arrFilter;
  };
  const handleGroupMenuGames = (listGames: any) => {
    const mergeArray: any = [];
    listGames.forEach((game: any) => {
      const mergeItem = {
        ...game,
        fathername:
          game.type === 3
            ? "Xổ Số Miền Bắc"
            : game.type === 2
            ? "Xổ Số Miền Trung"
            : game.type === 1
            ? "Xổ Số Miền Nam"
            : game.group,
        name:
          game.type === 3
            ? "mb"
            : game.type === 2
            ? "mt"
            : game.type === 1
            ? "mn"
            : game.type,
      };
      mergeArray.push(mergeItem);
    });
    return groupMenuByFathername(mergeArray);
  };

  const groupMenuByFathername = (menuArr: any) => {
    const groupMenu = menuArr.reduce((acc: any, item: any) => {
      if (!acc.has(item.fathername)) {
        acc.set(item.fathername, []);
      }
      acc.get(item.fathername).push(item);
      return acc;
    }, new Map());
    const result = [...groupMenu.entries()].map(([fathername, items]: any) => ({
      fathername,
      iconurl: items[0].iconurl,
      name: items[0].name,
      type: items[0].type,
      listMenuItem: items
        .map(({ id, name, desc, group, iconurl, type, url }: any) => ({
          id,
          name,
          desc,
          group,
          iconurl,
          type,
          url,
          isToday: listLotteryToday?.some(
            (lottery: any) => lottery.gameid === id
          ),
        }))
        .sort((a: any, b: any) => {
          if (b.isToday - a.isToday !== 0) {
            return b.isToday - a.isToday;
          }
          return compareVietnameseStrings(a.desc, b.desc);
        }),
    }));
    result.sort((a: any, b: any) => {
      const order: any = {
        "Xổ Số Nhanh": 0,
        "Xổ Số Miền Bắc": 1,
        "Xổ Số Miền Trung": 2,
        "Xổ Số Miền Nam": 3,
      };
      return order[a.fathername] - order[b.fathername];
    });
    return result;
  };

  const handleNavigate = (path: any, isPublic?: boolean) => {
    if (!isAuth) {
      if (isPublic === true) {
        navigate(path);
      } else {
        toastify("Vui lòng đăng nhập!", "warning");
        return;
      }
    } else {
      navigate(path);
    }
    if (onClose) {
      onClose();
    }
  };

  const toggleDropdown = (group: string) => {
    const newListGameConverted = listLotteryFilter.map((item: any) => {
      if (item.fathername === group) {
        item.isSelected = !item.isSelected;
      }
      return item;
    });
    setListLotteryFilter(newListGameConverted);
  };

  const hangleGetDay = (type: number, day: any) => {
    if (type === 1) setCurrentDaySouth(day);
    if (type === 2) setCurrentDayMiddle(day);
    setShowPopoverDays({ isShow: false, type: null });
  };

  return (
    <div
      className={classNames(
        `w-full flex ${className} h-full bg-white dark:bg-bgPrimary p-1 rounded-[10px]`,
        {
          "!py-4 !px-2": isMobile,
          "flex-col": isTablet || isMobile,
          "!h-[calc(100%-54px)] !overflow-auto": isMobile,
        }
      )}
    >
      {listLotteryFilter?.map((game: any, index: number) => (
        <div
          key={index}
          className={`min-h-9 rounded-md ${
            (isMobile || isTablet) && index > 0 ? "mt-2" : ""
          }`}
        >
          <div
            className="flex items-center justify-between px-2.5 text-primary cursor-pointer"
            onClick={() => toggleDropdown(game.fathername)}
          >
            <div
              className={classNames(
                "flex items-center justify-between w-full py-2 h-11 text-primary font-semibold",
                {
                  "text-primary": game.isSelected,
                  "!text-black dark:!text-white": isMobile,
                }
              )}
            >
              <span className={classNames("text-sm font-semibold italic")}>
                {game.fathername}
              </span>
              {[1, 2].includes(game.type) && (
                <div className="ml-2.5 relative" ref={popoverDayRef}>
                  <button
                    className="bg-secondary/20 text-primary hover:opacity-80 w-20 px-2 py-1 rounded-md text-[13px] font-medium whitespace-nowrap"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowPopoverDays({
                        isShow: true,
                        type: game.type,
                      });
                    }}
                  >
                    {formatGetTitleStatus(
                      game.type === 2 ? curretDayMiddle : curretDaySouth,
                      listDays,
                      "title"
                    )}
                  </button>
                  <div
                    className={classNames(
                      "absolute max-h-0 top-10 opacity-0 invisible right-0 z-50 transition-all duration-150 min-w-[84px]",
                      {
                        "!max-h-[9999px] !opacity-100 !visible":
                          showPopoverDays?.isShow &&
                          game.type === showPopoverDays?.type,
                      }
                    )}
                  >
                    <ListDaysPopover
                      getDay={(value: number) => hangleGetDay(game.type, value)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className={classNames(
              "transition-all duration-300 overflow-hidden",
              {
                "!max-h-[calc(100%-36px)] !overflow-auto":
                  !isMobile && !isTablet,
                "!max-h-[20vh] transition-all duration-300 !overflow-auto":
                  isTablet,
                "!max-h-[calc(100%-36px)] transition-all duration-300 !overflow-auto":
                  isMobile,
              }
            )}
          >
            <div
              className={classNames(
                "game-item p-2 my-2 rounded flex items-center text-text-secondary dark:text-white/40 hover:text-black dark:hover:text-white cursor-pointer"
              )}
              key={index}
              onClick={() =>
                handleNavigate(
                  `/ket-qua/${
                    game.name === "mb"
                      ? "truc-tiep-mien-bac"
                      : game.name === "mt"
                      ? "truc-tiep-mien-trung"
                      : "truc-tiep-mien-nam"
                  }`,
                  true
                )
              }
            >
              <span
                className={classNames(
                  "text-sm font-medium ml-3 mr-3 w-full truncate"
                )}
              >
                Trực Tiếp Xổ Số{" "}
                {game.name === "mb"
                  ? "Miền Bắc"
                  : game.name === "mt"
                  ? "Miền Trung"
                  : "Miền Nam"}
              </span>
              <BounceLoadingComponent length={3} color="#22C55E" size={2} />
            </div>

            {game.listMenuItem?.map((item: any, index: number) => (
              <div
                className={classNames(
                  "game-item p-2 my-2 rounded flex items-center text-text-secondary dark:text-white/40 hover:text-black dark:hover:text-white cursor-pointer",
                  {
                    "!text-black dark:!text-white": item.isActive,
                  }
                )}
                key={index}
                onClick={() =>
                  handleNavigate(
                    `/ket-qua/${convertNameLotteryToParam(item.desc)}`,
                    true
                  )
                }
              >
                <span
                  className={classNames(
                    "text-sm font-medium ml-3 w-full truncate"
                  )}
                >
                  {item.desc}
                </span>
                {item.isToday && (
                  <BounceLoadingComponent length={3} color="#22C55E" size={2} />
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MenuResult;
