import axiosClient from "./axiosInstance";
import {
  betLotteryI,
  payloadGetLotteryI,
  payloadScheduleGetLotteryI,
  paramsRecordLotteryI,
  paramsWinNumberI,
} from "../interfaces/lottery.interface";
import { formatArraygameid } from "utils/lotteryFormat";
import {
  paramsGetHistorySchedules,
  paramsGetScheduleCurrent,
} from "../interfaces/api.interface";

const lotteryApi = {
  getListSchedules(params: paramsGetHistorySchedules) {
    const url = "/api/v1/lottery/public/schedules/history";
    return axiosClient.get(url, { params });
  },
  getListSchedulesByDay(params: paramsGetScheduleCurrent) {
    const url = "/api/v1/lottery/public/schedules/historybyday";
    return axiosClient.get(url, { params });
  },
  getListSchedulesFastLottery(params: paramsGetHistorySchedules) {
    const url = "/api/v1/lottery/fastlottery/history";
    return axiosClient.get(url, { params });
  },
  getListSchedulesByPayload(payload: payloadScheduleGetLotteryI) {
    const params: any = {};
    let paramgameid = [];
    if (payload.gameid !== undefined) {
      paramgameid = formatArraygameid(payload.gameid);
    }
    if (payload.status !== undefined) {
      params.status = payload.status;
    }
    if (payload.pageSize !== undefined) {
      params.pageSize = payload.pageSize;
    }
    if (payload.offset !== undefined) {
      params.offset = payload.offset;
    }
    if (payload.sortField !== undefined) {
      params.sortField = payload.sortField;
    }
    if (payload.sortDir !== undefined) {
      params.sortDir = payload.sortDir;
    }
    if (payload.fromdate !== undefined) {
      params.fromdate = payload.fromdate;
    }
    if (payload.todate !== undefined) {
      params.todate = payload.todate;
    }
    const url = `/api/v1/lottery/schedules?${paramgameid}`;
    return axiosClient.get(url, { params });
  },
  getListSchedulesPrevious(params: paramsGetScheduleCurrent) {
    const url = "/api/v1/lottery/public/schedules/previous";
    return axiosClient.get(url, { params });
  },
  getListSchedulesCurrent(params: any) {
    const url = "/api/v1/lottery/public/schedules/current";
    return axiosClient.get(url, { params });
  },
  getDetailSchedulesPrevious(payload: payloadGetLotteryI) {
    const url = `/api/v1/lottery/schedule/${payload.gameid}/previous`;
    return axiosClient.get(url);
  },
  getDetailScheduleCurrent(payload: payloadGetLotteryI) {
    const url = `/api/v1/lottery/schedule/${payload.gameid}/current`;
    return axiosClient.get(url);
  },
  getModelsLottery(gameids: string) {
    const url = `/api/v1/lottery/models`;
    return axiosClient.get(url, {
      params: { gameids: gameids },
    });
  },
  getModelsLotteryByGame(gameid: number) {
    const url = `/api/v1/lottery/models/${gameid}`;
    return axiosClient.get(url);
  },
  getListGames() {
    const url = "/api/v1/lottery/public/constant/listgames";
    return axiosClient.get(url);
  },
  submitBetLottery(payload: betLotteryI[], params?: any) {
    const url = `/api/v1/lottery/bet`;
    return axiosClient.post(url, payload, { params });
  },
  cancelBetLottery(payload: { id: any }) {
    const url = `/api/v1/lottery/cancelbet?id=${payload.id}`;
    return axiosClient.post(url);
  },
  getGameRecordCurrentUser(params?: paramsRecordLotteryI) {
    const url = `/api/v1/lottery/gamerecords/currentuser`;
    return axiosClient.get(url, { params });
  },
  getGameRecordConstantStatus() {
    const url = `/api/v1/lottery/public/gamerecord/constant/status`;
    return axiosClient.get(url);
  },
  getTransactionConstantStatus() {
    const url = `/api/v1/user/transaction/constant/transtype`;
    return axiosClient.get(url);
  },
  getWinNumberStream() {
    const url = `/api/v1/lottery/public/schedules/winnumber/stream`;
    return axiosClient.get(url);
  },
  getWinNumber(params: paramsWinNumberI) {
    const url = `/api/v1/lottery/schedules/winnumber`;
    return axiosClient.get(url, { params });
  },
};

export default lotteryApi;
