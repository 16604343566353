import CommonLayout from "layout/CommonLayout";
import LandingPageLayout from "layout/LandingPageLayout";
import ResultLayout from "layout/ResultLayout";
import ContactPage from "pages/ContactPage/ContactPage";
import ResultHistoryPage from "pages/ResultHistoryPage/ResultHistoryPage";
import TodayResultPage from "pages/TodayResultPage/TodayResultPage";

export const mainRoutes = [
  {
    path: "/ket-qua/*",
    component: ResultLayout,
    isMain: false,
    isPublic: true,
  },
  {
    path: "/ve-chung-toi",
    component: LandingPageLayout,
    isMain: false,
    isPublic: true,
  },
  {
    path: "/trang/*",
    component: CommonLayout,
    isMain: false,
    isPublic: true,
  },
];

export const resultRoutes = [
  {
    paths: [
      "/",
      "/truc-tiep-mien-bac",
      "/truc-tiep-mien-trung",
      "/truc-tiep-mien-nam",
    ],
    component: TodayResultPage,
    isMain: true,
    isPublic: true,
  },
  {
    path: "/:idLottery",
    component: ResultHistoryPage,
    isMain: true,
    isPublic: true,
  },
];

export const commonRoutes = [
  {
    path: "/lien-he-quang-cao",
    component: ContactPage,
    isMain: true,
    isPublic: true,
  },
];
