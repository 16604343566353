import classNames from "classnames";
import LotteryTodaySection from "components/LotteryTodaySection/LotteryTodaySection";
import SideBar from "components/SideBar/SideBar";
import { toggleSidebar } from "features/layoutSlice";
import Header from "partials/Header/Header";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router";
import { resultRoutes } from "routes/routes";

const ResultLayout = () => {
  const { isMobile, isTablet, isMiniDesktop, showSidebar } = useSelector(
    (state: any) => state.layout
  );
  const dispatch = useDispatch();

  const chatRef = useRef<any>();
  const containerRef = useRef<HTMLDivElement>(null);

  const [showBoxChat, setShowBoxChat] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        chatRef.current &&
        !chatRef.current.contains(event.target) &&
        !isMobile
      ) {
        setShowBoxChat(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = showBoxChat ? "hidden" : "auto";
  }, [showBoxChat]);

  const handleToggleSidebar = (status: boolean) => {
    dispatch(toggleSidebar(status));
  };

  return (
    <div
      className={classNames(
        "main-layout w-full flex dark:bg-bgLayout bg-surface-primary",
        {
          "!bg-surface-primary dark:!bg-bgPrimary": isMobile,
        }
      )}
    >
      {(isMobile || isTablet || isMiniDesktop) && (
        <div className="sidebar z-30">
          <SideBar />
          <div
            className={classNames(
              "bg-black/40 opacity-0 transition-all duration-150 invisible ease-out fixed top-0 left-0 w-full h-full -z-[10]",
              {
                "!z-30 opacity-80 duration-150 ease-linear !visible":
                  showSidebar,
              }
            )}
            onClick={() => handleToggleSidebar(false)}
          ></div>
        </div>
      )}
      <div className="content-page w-full flex flex-col items-center min-h-screen">
        <div
          className={classNames("main-header w-full h-[84px]", {
            "!h-[56px]": isMobile,
          })}
        >
          <div className="fixed z-20 w-full min-w-[100vw]">
            <Header></Header>
          </div>
        </div>

        <div
          className={classNames(
            "content-home w-full h-full flex flex-col items-center flex-1"
          )}
        >
          <div className="w-full flex justify-center p-3 bg-bgPrimary relative z-10">
            <LotteryTodaySection />
          </div>
          <div
            className={`flex justify-center w-full ${
              isTablet ? "p-4 h-full" : isMobile ? "" : "py-8 px-3 h-full"
            } max-w-[1440px] ${
              isMobile ? "bg-surface-primary dark:bg-bgPrimary" : ""
            }`}
          >
            <div className="w-full max-w-[1280px]" ref={containerRef}>
              <Routes>
                {resultRoutes?.map((route, index) => {
                  const SubPage = route.component;
                  if (route.paths) {
                    return route.paths.map((path) => (
                      <Route key={path} path={path} element={<SubPage />} />
                    ));
                  } else {
                    return (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={<SubPage />}
                      />
                    );
                  }
                })}
                <Route path="*" element={<Navigate to={"/"} replace />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultLayout;
