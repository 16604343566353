import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatDateBasic } from "utils/formateDate";
import {
  convertNameLotteryToParam,
  formatGetNameLottery,
  handleCheckDauDuoiHighlight,
  numberOfPrizes,
} from "utils/lotteryFormat";
import FadeLoaderComponent from "./FadeLoaderComponent";
import "./Table.scss";
import classNames from "classnames";
import RandomNumber from "components/RandomNumber/RandomNumber";
interface PropsI {
  data: any;
  listName: any;
  region: string;
  typeSlice: string;
  isToday: boolean;
  changedPrizesInfo: any;
  dataHoverDauDuoi: any;
  dataSelectDauDuoi: any;
  listDataHoverDauDuoi: any;
  listDataSelectDauDuoi: any;
}
function TableLotteryXSTTComponent({
  data,
  listName,
  region,
  typeSlice,
  isToday,
  changedPrizesInfo,
  dataHoverDauDuoi,
  dataSelectDauDuoi,
  listDataHoverDauDuoi,
  listDataSelectDauDuoi,
}: PropsI) {
  const { listGames } = useSelector((state: any) => state.lottery);
  const { isMobile } = useSelector((state: any) => state.layout);
  const handleConvertResultPrize = (arrPrize: any, namePrize: string) => {
    const defaultLength = (() => {
      if (region === "mb") {
        if (["giaibay", "giaitu"].includes(namePrize)) return 4;
        if (namePrize === "giaisau") return 3;
        if (["giaiba", "giainam"].includes(namePrize)) return 6;
        if (namePrize === "giainhi") return 2;
        return 1;
      } else {
        if (namePrize === "giaisau") return 3;
        if (namePrize === "giaitu") return 7;
        if (namePrize === "giaiba") return 2;
        return 1;
      }
    })();

    const resultArray: any[] = [];

    arrPrize?.forEach((prize: string) => {
      resultArray.push(prize?.length > 0 ? prize : "-");
    });

    while (resultArray?.length < defaultLength) {
      resultArray?.push("-");
    }

    return resultArray;
  };

  const handleSliceNumber = (value: string) => {
    return typeSlice === "3so"
      ? value.slice(-3)
      : typeSlice === "2so"
      ? value.slice(-2)
      : value;
  };

  const handleRenderPrize = (
    prizeValue: any,
    indexParent: number,
    indexChild: number
  ) => {
    if (prizeValue) {
      return handleSliceNumber(prizeValue);
    }
  };

  const isChangedPrize = (gameid: number, prizeName: string, index: number) => {
    return changedPrizesInfo?.some((prize: any) => {
      return prize.gameid === gameid && prize[prizeName]?.includes(index);
    });
  };

  const renderFilted = (
    value: any,
    index: any,
    prizeIndex: any,
    length: number
  ) => {
    const listDataSelectDauDuoiByIndex = listDataSelectDauDuoi?.find(
      (itemSelect: any) => itemSelect.index === index
    );
    const listDataHoverDauDuoiByIndex = listDataHoverDauDuoi?.find(
      (itemHover: any) => itemHover.index === index
    );
    const dataHoverByIndex =
      dataHoverDauDuoi?.indexParent === index ? dataHoverDauDuoi : null;

    const dataSelectByIndex = dataSelectDauDuoi?.find(
      (itemSelect: any) => itemSelect.index === index
    );

    return (
      <Fragment>
        {value && String(value) !== "-" ? (
          <span
            className="flex items-center"
            dangerouslySetInnerHTML={{
              __html: handleCheckDauDuoiHighlight(
                handleRenderPrize(value, index, prizeIndex),
                dataHoverByIndex,
                dataSelectByIndex,
                listDataHoverDauDuoiByIndex,
                listDataSelectDauDuoiByIndex
              ),
            }}
          ></span>
        ) : (
          <FadeLoaderComponent key={index} />
          // <Fragment>
          //   {Array.from({ length: Number(length) }, (_, index) => (
          //   <FadeLoaderComponent key={index} />
          // ))}
          // </Fragment>
        )}
      </Fragment>
    );
  };

  return (
    <table width="100%" className="text-black dark:text-white bkqmiennam">
      <tbody>
        <tr>
          <td width={isMobile ? "60" : "100"}>
            <table className="leftcl" width="100%">
              <tbody>
                <tr className="bg-surface-tertiary dark:bg-bgPrimary">
                  <td className="thu h-9 text-sm md:text-[10px] border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center">
                    {formatDateBasic(data && data[0]?.timeDay)}
                  </td>
                </tr>

                <tr>
                  <td
                    className={classNames(
                      "h-9 text-sm md:text-[10px] font-semibold border-t border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center giai7",
                      { "!h-6": isMobile }
                    )}
                  >
                    Tám
                  </td>
                </tr>
                <tr>
                  <td
                    className={classNames(
                      "h-[26px] text-sm md:text-[10px] font-semibold border-t border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center giai7",
                      { "!h-6": isMobile }
                    )}
                  >
                    Bảy
                  </td>
                </tr>
                <tr>
                  <td
                    className={classNames(
                      "h-16 text-sm md:text-[10px] font-semibold border-t border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center giai6",
                      { "!h-14": isMobile }
                    )}
                  >
                    Sáu
                  </td>
                </tr>
                <tr>
                  <td
                    className={classNames(
                      "h-[26px] text-sm md:text-[10px] font-semibold border-t border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center giai5",
                      { "!h-6": isMobile }
                    )}
                  >
                    Năm
                  </td>
                </tr>
                <tr className="">
                  <td
                    className={classNames(
                      "h-44 text-sm md:text-[10px] font-semibold border-t border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center giai4",
                      { "!h-[120px]": isMobile }
                    )}
                  >
                    Tư
                  </td>
                </tr>
                <tr className="">
                  <td
                    className={classNames(
                      "h-14 text-sm md:text-[10px] font-semibold border-t border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center giai3",
                      { "!h-10": isMobile }
                    )}
                  >
                    Ba
                  </td>
                </tr>
                <tr className="">
                  <td
                    className={classNames(
                      "h-[26px] text-sm md:text-[10px] font-semibold border-t border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center giai2",
                      { "!h-6": isMobile }
                    )}
                  >
                    Nhì
                  </td>
                </tr>
                <tr>
                  <td
                    className={classNames(
                      "h-[26px] text-sm md:text-[10px] font-semibold border-t border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center giai1",
                      { "!h-6": isMobile }
                    )}
                  >
                    Nhất
                  </td>
                </tr>
                <tr>
                  <td
                    className={classNames(
                      "h-9 text-sm md:text-[10px] font-semibold border-t border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center text-center giaidb",
                      { "!h-6": isMobile }
                    )}
                  >
                    Đặc Biệt
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td valign="top">
            <table width="100%">
              <tbody>
                <tr>
                  {data?.length > 0 &&
                    data?.map((item: any, index: any) => (
                      <td
                        valign="top"
                        key={index}
                        width={100 / data?.length + "%"}
                      >
                        <table
                          className="rightcl bangketquaSo bangketquaSo_0"
                          width="100%"
                        >
                          <tbody>
                            <tr>
                              <td
                                className={classNames(
                                  "thu h-9 text-base bg-surface-tertiary dark:bg-bgPrimary font-bold flex items-center justify-center text-center text-primary",
                                  { "!text-[10px]": isMobile }
                                )}
                              >
                                <h3>
                                  <Link
                                    to={`/ket-qua/${convertNameLotteryToParam(
                                      formatGetNameLottery(
                                        item?.gameid,
                                        listGames
                                      )
                                    )}`}
                                    className="hover:underline"
                                  >
                                    {formatGetNameLottery(
                                      item?.gameid,
                                      listGames
                                    )}
                                  </Link>
                                </h3>
                              </td>
                            </tr>

                            <tr>
                              <td
                                className={classNames(
                                  "h-9 border-t border-borderLightPrimary dark:border-borderPrimary border-l  giaitam flex items-center justify-center gap-0 px-0 flex-col",
                                  { "!h-6": isMobile }
                                )}
                              >
                                {handleConvertResultPrize(
                                  item.giaitam?.toString()?.split(","),
                                  "giaitam"
                                ).map((giaitam: any, prizeIndex: number) => (
                                  <div
                                    key={prizeIndex}
                                    className="giaiSo text-sm  h-[24px] w-full rounded-md flex items-center justify-center text-warning-500 font-semibold"
                                  >
                                    <span
                                      className={classNames(
                                        "w-full rounded-md flex items-center justify-center text-xl",
                                        { "!text-sm": isMobile }
                                      )}
                                    >
                                      {/* <RandomResultSlideComponent length={5} size='big' /> */}
                                      {isChangedPrize(
                                        item?.gameid,
                                        "giaitam",
                                        prizeIndex
                                      ) ? (
                                        <RandomNumber
                                          length={numberOfPrizes(
                                            region,
                                            "giaitam",
                                            typeSlice
                                          )}
                                        />
                                      ) : (
                                        renderFilted(
                                          giaitam,
                                          index,
                                          prizeIndex,
                                          numberOfPrizes(
                                            region,
                                            "giaitam",
                                            typeSlice
                                          )
                                        )
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td
                                className={classNames(
                                  "h-[26px] border-t border-borderLightPrimary dark:border-borderPrimary border-l giaibay flex items-center justify-center gap-0 px-0 flex-col",
                                  { "!h-6": isMobile }
                                )}
                              >
                                {handleConvertResultPrize(
                                  item.giaibay?.toString()?.split(","),
                                  "giaibay"
                                ).map((giaibay: any, prizeIndex: number) => (
                                  <div
                                    key={prizeIndex}
                                    className={classNames(
                                      "giaiSo text-sm h-6 w-full rounded-md flex items-center justify-center dark:text-white text-black font-semibold",
                                      { "!text-[10px]": isMobile }
                                    )}
                                  >
                                    <span className="w-full rounded-md flex items-center justify-center flex-nowrap">
                                      {isChangedPrize(
                                        item?.gameid,
                                        "giaibay",
                                        prizeIndex
                                      ) ? (
                                        <RandomNumber
                                          length={numberOfPrizes(
                                            region,
                                            "giaibay",
                                            typeSlice
                                          )}
                                        />
                                      ) : (
                                        renderFilted(
                                          giaibay,
                                          index,
                                          prizeIndex,
                                          numberOfPrizes(
                                            region,
                                            "giaibay",
                                            typeSlice
                                          )
                                        )
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td
                                className={classNames(
                                  "h-16 border-t border-borderLightPrimary dark:border-borderPrimary border-l  giaisau flex items-center justify-center gap-0 py-1 flex-col",
                                  { "!h-14": isMobile }
                                )}
                              >
                                {handleConvertResultPrize(
                                  item.giaisau?.toString()?.split(","),
                                  "giaisau"
                                ).map((giaisau: any, prizeIndex: number) => (
                                  <div
                                    key={prizeIndex}
                                    className={classNames(
                                      "giaiSo h-[24px] w-full text-sm  rounded-md flex items-center justify-center dark:text-white text-black font-semibold",
                                      { "!text-[10px]": isMobile }
                                    )}
                                  >
                                    <span className="w-full rounded-md flex  items-center justify-center">
                                      {isChangedPrize(
                                        item?.gameid,
                                        "giaisau",
                                        prizeIndex
                                      ) ? (
                                        <RandomNumber
                                          length={numberOfPrizes(
                                            region,
                                            "giaisau",
                                            typeSlice
                                          )}
                                        />
                                      ) : (
                                        renderFilted(
                                          giaisau,
                                          index,
                                          prizeIndex,
                                          numberOfPrizes(
                                            region,
                                            "giaisau",
                                            typeSlice
                                          )
                                        )
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td
                                className={classNames(
                                  "h-[26px] border-t border-borderLightPrimary dark:border-borderPrimary border-l giaibay flex items-center justify-center gap-0 px-0 flex-col",
                                  { "!h-6": isMobile }
                                )}
                              >
                                {/* <td className="h-14 border-t border-borderLightPrimary dark:border-borderPrimary border-l giainam  p-2"> */}
                                {handleConvertResultPrize(
                                  item.giainam?.toString()?.split(","),
                                  "giainam"
                                ).map((giainam: any, prizeIndex: number) => (
                                  <div
                                    key={prizeIndex}
                                    className={classNames(
                                      "giaiSo text-sm h-6 w-full rounded-md flex items-center justify-center dark:text-white text-black font-semibold",
                                      { "!text-[10px]": isMobile }
                                    )}
                                  >
                                    <span className="w-full rounded-md flex items-center justify-center">
                                      {isChangedPrize(
                                        item?.gameid,
                                        "giainam",
                                        prizeIndex
                                      ) ? (
                                        <RandomNumber
                                          length={numberOfPrizes(
                                            region,
                                            "giainam",
                                            typeSlice
                                          )}
                                        />
                                      ) : (
                                        renderFilted(
                                          giainam,
                                          index,
                                          prizeIndex,
                                          numberOfPrizes(
                                            region,
                                            "giainam",
                                            typeSlice
                                          )
                                        )
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td
                                className={classNames(
                                  "h-44 border-t border-borderLightPrimary dark:border-borderPrimary border-l giaitu flex items-center justify-center gap-0 py-1 flex-col",
                                  { "!h-[120px]": isMobile }
                                )}
                              >
                                {handleConvertResultPrize(
                                  item.giaitu?.toString()?.split(","),
                                  "giaitu"
                                ).map((giaitu: any, prizeIndex: number) => (
                                  <div
                                    key={prizeIndex}
                                    className={classNames(
                                      "giaiSo h-[24px] w-full rounded-md flex items-center justify-center dark:text-white text-black font-semibold",
                                      { "!text-[10px] !h-auto": isMobile },
                                      { "text-sm": !isMobile }
                                    )}
                                  >
                                    <span className="w-full rounded-md flex  items-center justify-center">
                                      {isChangedPrize(
                                        item?.gameid,
                                        "giaitu",
                                        prizeIndex
                                      ) ? (
                                        <RandomNumber
                                          length={numberOfPrizes(
                                            region,
                                            "giaitu",
                                            typeSlice
                                          )}
                                        />
                                      ) : (
                                        renderFilted(
                                          giaitu,
                                          index,
                                          prizeIndex,
                                          numberOfPrizes(
                                            region,
                                            "giaitu",
                                            typeSlice
                                          )
                                        )
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td
                                className={classNames(
                                  "h-14 border-t border-borderLightPrimary dark:border-borderPrimary border-l giaiba flex items-center justify-center gap-0 py-1 flex-col",
                                  { "!h-10": isMobile }
                                )}
                              >
                                {/* <td className="h-14 border-t border-borderLightPrimary dark:border-borderPrimary border-l  giaiba flex items-center justify-center gap-0 flex-col py-1"> */}
                                {handleConvertResultPrize(
                                  item.giaiba?.toString()?.split(","),
                                  "giaiba"
                                ).map((giaiba: any, prizeIndex: number) => (
                                  <div
                                    key={prizeIndex}
                                    className={classNames(
                                      "giaiSo text-sm h-[24px] w-full rounded-md flex items-center justify-center dark:text-white text-black font-semibold ",
                                      { "!text-[10px]": isMobile }
                                    )}
                                  >
                                    <span className="w-full rounded-md flex  items-center justify-center">
                                      {isChangedPrize(
                                        item?.gameid,
                                        "giaiba",
                                        prizeIndex
                                      ) ? (
                                        <RandomNumber
                                          length={numberOfPrizes(
                                            region,
                                            "giaiba",
                                            typeSlice
                                          )}
                                        />
                                      ) : (
                                        renderFilted(
                                          giaiba,
                                          index,
                                          prizeIndex,
                                          numberOfPrizes(
                                            region,
                                            "giaiba",
                                            typeSlice
                                          )
                                        )
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </td>
                            </tr>

                            <tr>
                              <td
                                className={classNames(
                                  "h-[26px] border-t border-borderLightPrimary dark:border-borderPrimary border-l  giainhi flex items-center justify-center gap-0 px-0 flex-col",
                                  { "!h-6": isMobile }
                                )}
                              >
                                {handleConvertResultPrize(
                                  item.giainhi?.toString()?.split(","),
                                  "giainhi"
                                ).map((giainhi: any, prizeIndex: number) => (
                                  <div
                                    key={prizeIndex}
                                    className={classNames(
                                      "giaiSo text-sm  h-[24px] w-full rounded-md flex items-center justify-center dark:text-white text-black font-semibold",
                                      { "!text-[10px]": isMobile }
                                    )}
                                  >
                                    <span className="w-full rounded-md flex  items-center justify-center">
                                      {isChangedPrize(
                                        item?.gameid,
                                        "giainhi",
                                        prizeIndex
                                      ) ? (
                                        <RandomNumber
                                          length={numberOfPrizes(
                                            region,
                                            "giainhi",
                                            typeSlice
                                          )}
                                        />
                                      ) : (
                                        renderFilted(
                                          giainhi,
                                          index,
                                          prizeIndex,
                                          numberOfPrizes(
                                            region,
                                            "giainhi",
                                            typeSlice
                                          )
                                        )
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td
                                className={classNames(
                                  "h-[26px] border-t border-borderLightPrimary dark:border-borderPrimary border-l  giainhat flex items-center justify-center gap-0 px-0 flex-col",
                                  { "!h-6": isMobile }
                                )}
                              >
                                {handleConvertResultPrize(
                                  item.giainhat?.toString()?.split(","),
                                  "giainhat"
                                ).map((giainhat: any, prizeIndex: number) => (
                                  <div
                                    key={prizeIndex}
                                    className={classNames(
                                      "giaiSo text-sm  h-[24px] w-full rounded-md flex items-center justify-center dark:text-white text-black font-semibold",
                                      { "!text-[10px]": isMobile }
                                    )}
                                  >
                                    <span className="w-full rounded-md flex  items-center justify-center">
                                      {isChangedPrize(
                                        item?.gameid,
                                        "giainhat",
                                        prizeIndex
                                      ) ? (
                                        <RandomNumber
                                          length={numberOfPrizes(
                                            region,
                                            "giainhat",
                                            typeSlice
                                          )}
                                        />
                                      ) : (
                                        renderFilted(
                                          giainhat,
                                          index,
                                          prizeIndex,
                                          numberOfPrizes(
                                            region,
                                            "giainhat",
                                            typeSlice
                                          )
                                        )
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </td>
                            </tr>

                            <tr>
                              <td
                                className={classNames(
                                  "h-9 border-t border-borderLightPrimary dark:border-borderPrimary border-l  giaidb flex items-center justify-center gap-0 px-0 flex-col",
                                  { "!h-6": isMobile }
                                )}
                              >
                                {handleConvertResultPrize(
                                  item.giaidb?.toString()?.split(","),
                                  "giaidb"
                                ).map((giaidb: any, prizeIndex: number) => (
                                  <div
                                    key={prizeIndex}
                                    className="giaiSo text-sm  h-[24px] w-full rounded-md flex items-center justify-center text-warning-500 font-semibold "
                                  >
                                    <span
                                      className={classNames(
                                        "w-full rounded-md flex  items-center justify-center text-xl",
                                        { "!text-[12px]": isMobile }
                                      )}
                                    >
                                      {isChangedPrize(
                                        item?.gameid,
                                        "giaidb",
                                        prizeIndex
                                      ) ? (
                                        <RandomNumber
                                          length={numberOfPrizes(
                                            region,
                                            "giaidb",
                                            typeSlice
                                          )}
                                        />
                                      ) : (
                                        renderFilted(
                                          giaidb,
                                          index,
                                          prizeIndex,
                                          numberOfPrizes(
                                            region,
                                            "giaidb",
                                            typeSlice
                                          )
                                        )
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    ))}
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default TableLotteryXSTTComponent;
